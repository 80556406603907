import React from "react";
import Layout from "../components/Layout";

export default function AboutPage() {
  return (
    <Layout>
      <div>ABOUT</div>
    </Layout>
  );
}
